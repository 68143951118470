import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Base } from './base';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { PhobosTaskInstance } from '../domain/phobos-task-instance';

@Injectable({
   providedIn: 'root'
})
export class SearchService extends Base {
   constructor(private http: HttpClient) {
      super();
   }

   getTasksBySearchTerm(
      searchTerm: string,
      propId: number
   ): Observable<PhobosTaskInstance[]> {
      return this.http.get<PhobosTaskInstance[]>(
         `${environment.apiEndpoint}/search`,
         {
            params: {
               propertyId: encodeURIComponent(propId),
               searchTerm: encodeURIComponent(searchTerm)
            }
         }
      );
   }
}
