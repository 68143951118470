<div class="p-grid">

    <div class="p-col-12 p-md-8 p-lg-6 relative card container">
        <div id="mention_table">
            <div class="tyalls-table-name mb-3 flex">
                <h6 class="tyalls-table-heading">Billing Portal</h6>
            </div>
        </div>

        <div class="flex justify-content-between w-full content-center mt-4">
            <div>
                Securely manage your billing details, update your payment methods, and view past invoices.
            </div>

            <div class="mt-3 pr-5">
            </div>
        </div>

        <div class="flex justify-content-between w-full content-center mt-4">
            <div>
                <button (click)="openBillingPortal()" class="p-button" label="Open Billing Portal" type="button">
                    @if (this.loading) {
                    Re-directing you to billing portal
                    } @else {
                    Open Billing Portal
                    }
                </button>
            </div>
            <div class="mt-3 pr-5">
            </div>
        </div>
    </div>