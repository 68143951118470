import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Base } from './base';
import { Observable } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class BillingPortalService extends Base {
   constructor(private http: HttpClient) {
      super();
   }

   get(propertyId: number): Observable<{ url: string }> {
      const endpoint = `${environment.apiEndpoint}/payment/customer/portal`;
      return this.http.get<{ url: string }>(endpoint, {
         params: {
            PropertyId: propertyId.toString(),
            ReturnUrl: `${window.location.origin}/dashboard`
         }
      });
   }
}
