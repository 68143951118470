import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class MenuStoreService {
   private IsMenuExpandedKey: string = 'MENU_EXPANDED';

   private _menuState: ReplaySubject<boolean> = new ReplaySubject(1, Infinity);
   public readonly menuState: Observable<boolean> = this._menuState.asObservable();

   constructor() {
      const lastMenuState = localStorage.getItem(this.IsMenuExpandedKey);
      if (lastMenuState) {
         this.setMenuState(Boolean(lastMenuState));
      }
   }

   public setMenuState(value: boolean, updateLocalStorage: boolean = true) {
      if (updateLocalStorage) {
         localStorage.setItem(this.IsMenuExpandedKey, String(value));
      }
      this._menuState.next(value);
   }
}
