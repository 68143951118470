import { Component, OnInit } from '@angular/core';
import { Property } from '../../domain/property';
import { BillingPortalService } from '../../services/billing-portal.service';
import { PropertyStoreService } from '../../services/stores/property-store.service';

@Component({
   selector: 'app-billing-portal',
   templateUrl: './billing-portal.component.html',
   styleUrls: ['./billing-portal.component.scss']
})
export class BillingPortalComponent implements OnInit {
   loading = false;
   currentProperty: Property | null;

   constructor(
      private billingPortalService: BillingPortalService,
      private propStore: PropertyStoreService
   ) {
      this.currentProperty = null;
   }

   async ngOnInit(): Promise<void> {
      this.propStore.currentProperty.subscribe((value) => {
         this.currentProperty = value;
      });
   }

   openBillingPortal(): void {
      if (!this.currentProperty || !this.currentProperty.id) {
         console.error('No current property selected.');
         return;
      }
      this.loading = true;
      this.billingPortalService.get(this.currentProperty.id).subscribe({
         next: (response: any) => {
            const portalUrl = response?.portalUrl;
            if (portalUrl) {
               window.open(portalUrl, '_blank');
            } else {
               console.error('No URL returned from the API.');
            }
            this.loading = false;
         },
         error: (error) => {
            console.error('Failed to fetch billing portal URL:', error);
            this.loading = false;
         }
      });
   }
}
