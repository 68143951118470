import { WorkflowType } from './enums/workflow-type';
import { Task } from './task';

export class PhobosTaskInstance {
   id: number = 0;
   propertyId: number = 0;
   taskId: number = 0;
   documentId: string = '';
   createdDate: string = '';
   completedDate: string = '';
   createdBy: number = 0;
   name: string = '';
   description: string = '';
   isAssignedToUser: boolean = false;
   type: WorkflowType = WorkflowType.Daily;

   constructor() {}

   public toV1Task(): Task {
      const result = new Task();
      result.processId = this.id;
      result.type = this.type;
      result.name = this.name;
      result.description = this.description;
      result.isAssignedToUser = this.isAssignedToUser;
      result.dueDate = this.createdDate;

      return result;
   }
}
