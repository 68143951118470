<app-add-tasks-dialog [isVisible]="this.isDialogVisible" [groups]="this.groups" [schedules]="this.autoRunSchedules"
	(dialogClose)="onDialogClose()" [currentProp]="this.currentProp" [currentTaskTemplate]="this.currentTaskTemplate"
	[tasks]="tasks"></app-add-tasks-dialog>
<div *ngIf="this.isLoading" class="progress-spinner">
	<p-progressSpinner></p-progressSpinner>
</div>
<div *ngIf="!this.isLoading" class="card container">
	<div id="mention_table">
		<div class="tyalls-table-name mb-3">
			<h6 class="tyalls-table-heading">Tasks Management</h6>
		</div>
	</div>

	<div class="mb-4">
		<app-table-heading-row (mainButtonClick)="onDialogOpen(true,undefined)" [buttons]="this.headingButtons"
			[hasButton]="true" [isLoading]="this.isLoading" headingText=""></app-table-heading-row>
	</div>
	<p-table #taskManagementTable [value]="tasks" dataKey="id" [globalFilterFields]="['type', 'type', 'name']">
		<ng-template pTemplate="header">
			<tr>
				<th style="width: 3rem"></th>
				<th>Task</th>
				<th class="text-center" pSortableColumn="type">Type
					<p-sortIcon field="type" />
				</th>
				<th>Review Groups</th>
				<th>Instructions</th>
				<th pSortableColumn="isAutoRunEnabled">
					<div class="flex-container">
						<div class="text-center">Auto Run
							<p-columnFilter type="boolean" field="isAutoRunEnabled" display="menu" />
						</div>
					</div>
				</th>
				<th>
					<div class="flex-container">Edit</div>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="caption">
			<div class="flex">
				<div class="sm-2 md-2 lg-2">
					<p-button label="Clear" [outlined]="true" icon="pi pi-filter-slash"
						(onClick)="clear(taskManagementTable)" />
				</div>
				<div class="col-offset-9 sm-4 md-4 lg-4 col-order-last">
					<span class="p-input-icon-left ml-auto">
						<i class="pi pi-search"></i>
						<input pInputText type="text" [(ngModel)]="searchValue"
							(input)="filterTable($event, taskManagementTable)" placeholder="Search keyword" />
					</span>
				</div>
			</div>
		</ng-template>

		<ng-template pTemplate="body" let-task let-expanded="expanded">
			<tr>
				<td>
					<button type="button" pButton pRipple [pRowToggler]="task"
						class="p-button-text p-button-rounded p-button-plain"
						[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
				</td>
				<td class="bold">
					<p-chip *ngIf="!task.isDefaultTemplate" pTooltip="Provided By {{ task.orgName }}"
						label="{{Array.from(task.orgName)[0]}}" class="align-items-center justify-content-center" />
					{{ task.name }}
				</td>
				<td>
					<div class="flex justify-content-center min-column-width">
						<div [ngClass]="getTaskClass(task.type)">
							{{ handleTaskType(task.type) }}
						</div>
					</div>
				</td>
				<td>
					<div class="max-column-width">
						<ng-container *ngFor="let stage of task.stages;last as isLast">
							{{ getGroupNameForStage(stage) }}
							<span *ngIf="!isLast">,</span>
						</ng-container>
					</div>
				</td>
				<td class="w-2">
					<div>
						{{
						task.description?.length > 60
						? (task.description | slice: 0 : 60) + "..."
						: task.description
						}}
					</div>
				</td>
				<td class="pointer">
					<div (click)="playPauseAutoRun(task)" class="flex-container">
						<i pTooltip="Run/Pause task" tooltipPosition="top" [ngClass]="
								task.isAutoRunEnabled
									? 'fas fa-sharp fa-solid fa-play'
									: 'fas fa-sharp fa-solid fa-pause'
							" class="layout-menuitem-icon"></i>
					</div>
				</td>
				<td>
					<div class="flex-container">
						<button [disabled]="handleDisableEditButton(task.type)" (click)="
								onDialogOpen(
									false,
									task
								)
							" class="center w-20 p-button-rounded" label="Edit" pButton pRipple type="button"></button>
					</div>
				</td>
			</tr>
		</ng-template>



		<ng-template pTemplate="rowexpansion" let-task>
			<tr>
				<td colspan="7">
					<div class="p-3">
						<p-table [value]="[task.meta]" dataKey="id">
							<ng-template pTemplate="header">
			<tr>
				<th>Created By</th>
				<th>Created Date</th>
				<th>Last Modified By</th>
				<th>Last Modified Date</th>
				<th>AI Enabled</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-meta>
			<tr>
				<td>
					{{ meta?.createdBy?.firstName }}
					{{ meta?.createdBy?.lastName }}
				</td>
				<td>{{ formatDate(meta?.createdDate) }}</td>
				<td>
					{{ meta?.lastModifiedBy?.firstName }}
					{{ meta?.lastModifiedBy?.lastName }}
				</td>
				<td>{{ formatDate(meta?.lastModifiedDate) }}</td>
				<td>{{ meta?.isEnabledAi }}</td>
			</tr>
		</ng-template>

		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="5">No metadata.</td>
			</tr>
		</ng-template>
	</p-table>
</div>
</td>
</tr>
</ng-template>
</p-table>
</div>