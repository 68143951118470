import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, ReplaySubject } from 'rxjs';
import { Task } from '../../domain/task';
import { TaskService } from '../task.service';
import { PropertyStoreService } from './property-store.service';
import { IRefreshable } from './irefreshable';
import { DateTime } from 'luxon';
import { Property } from '../../domain/property';
import { LoadingOverlayStoreService } from './loading-overlay-store.service';
import { DailyTaskDetail } from '../../domain/daily-task-detail';
import { DataStoreService } from '../data-store.service';
import { LoadingState } from '../../domain/loading-state';
import { WorkflowStoreService } from './workflow-store.service';
import { WorkflowType } from '../../domain/enums/workflow-type';
import { UserPermissions } from '../../domain/security/user-permissions';
import { PermissionsService } from '../permissions.service';
import { PhobosTaskTemplate } from '../../domain/phobos-task-template';
import { CreateTaskRequest } from '../request/create-task-request';
import { UpdateTaskRequest } from '../request/update-task-request';

@Injectable({
   providedIn: 'root'
})
export class WorkflowTasksStoreService implements IRefreshable {
   userPermissions: UserPermissions;
   private MyTaskSelectedDateStorageKey: string = 'LAST_MY_TASK_SELECTED_DATE';
   private _unclaimedTasks: BehaviorSubject<Task[]> = new BehaviorSubject(
      new Array<Task>()
   );
   public readonly unclaimedTasks: Observable<Task[]> =
      this._unclaimedTasks.asObservable();
   private _PhobosTask: BehaviorSubject<Task[]> = new BehaviorSubject(
      new Array<Task>()
   );
   private _claimedTasks: BehaviorSubject<Task[]> = new BehaviorSubject(
      new Array<Task>()
   );
   public readonly claimedTasks: Observable<Task[]> =
      this._claimedTasks.asObservable();
   private currentPropertyId: number | undefined;
   //Daily Task Data
   private _myDailyTasksBySelectedDate: BehaviorSubject<DailyTaskDetail[]> =
      new BehaviorSubject(new Array<DailyTaskDetail>());
   public readonly myDailyTasksBySelectedDate: Observable<DailyTaskDetail[]> =
      this._myDailyTasksBySelectedDate.asObservable();
   private _allDailyTasksBySelectedDate: BehaviorSubject<DailyTaskDetail[]> =
      new BehaviorSubject(new Array<DailyTaskDetail>());
   public readonly allDailyTasksBySelectedDate: Observable<DailyTaskDetail[]> =
      this._allDailyTasksBySelectedDate.asObservable();
   //Task Data
   private _myTasksBySelectedDate: BehaviorSubject<Task[]> = new BehaviorSubject(
      new Array<Task>()
   );
   public readonly myTasksBySelectedDate: Observable<Task[]> =
      this._myTasksBySelectedDate.asObservable();
   private _allTasksBySelectedDate: BehaviorSubject<Task[]> = new BehaviorSubject(
      new Array<Task>()
   );
   public readonly allTasksBySelectedDate: Observable<Task[]> =
      this._allTasksBySelectedDate.asObservable();
   private _lastWorkflowRunTasks: BehaviorSubject<Task[]> = new BehaviorSubject(
      new Array<Task>()
   );
   public readonly lastWorkflowRunTasks: Observable<Task[]> =
      this._lastWorkflowRunTasks.asObservable();
   private _myTasksDate: ReplaySubject<Date> = new ReplaySubject(1, Infinity);
   public readonly myTasksDate: Observable<Date> = this._myTasksDate.asObservable();
   private currentTaskDate: Date | undefined;
   private _lastWorkflowRunTaskDate: BehaviorSubject<DateTime> = new BehaviorSubject(
      DateTime.now()
   );
   public readonly lastWorkflowRunTaskDate: Observable<DateTime> =
      this._lastWorkflowRunTaskDate.asObservable();

   constructor(
      private propertyStore: PropertyStoreService,
      private taskService: TaskService,
      private loadingOverlayStore: LoadingOverlayStoreService,
      private dataStoreService: DataStoreService,
      private workflowStore: WorkflowStoreService,
      public permissionService: PermissionsService
   ) {
      this.userPermissions = new UserPermissions();
      from(this.permissionService.getPermission()).subscribe(
         (value: UserPermissions) => {
            this.userPermissions = value;
         }
      );
      this.myTasksDate.subscribe(() => {
         this.getTaskData();
      });
      const savedDateString = localStorage.getItem(this.MyTaskSelectedDateStorageKey);
      let savedSelectedDate = new Date();
      if (savedDateString) {
         savedSelectedDate = DateTime.fromISO(savedDateString).toJSDate();
      }

      this.setMyTasksDate(savedSelectedDate);
      propertyStore.currentProperty.subscribe((property) => {
         if (this.compareProperties(property)) {
            this.currentPropertyId = property.id;
            this.getData();
            this.getTaskData();
            this.getLastWorkflowRunTasks();
         }
      });
      dataStoreService.refreshTaskDataSubject.subscribe((value) => {
         if (this.currentPropertyId && this.currentTaskDate) {
            const currentDate = DateTime.fromJSDate(this.currentTaskDate);
            const nextDay = currentDate.plus({ days: 1 });

            if (value.MyTasks) {
               this.updateAssignedTasks(currentDate, nextDay);
            }
            if (value.AllPropertyTasks) {
               this.updateAllPropertyTasks(currentDate, nextDay);
            }
            if (value.UnclaimedTasks) {
               this.updateUnclaimedTasks();
            }
            if (value.ClaimedTasks) {
               this.updateClaimedTasks();
            }
            const currentMonth = currentDate.startOf('month');
            const nextMonth = currentDate.plus({ months: 1 });
            if (value.MyTasksMonthly) {
               this.updateMyTasksMonthly(currentMonth, nextMonth);
            }
            if (value.AllTasksMonthly) {
               this.updateAllTaskMonthly(currentMonth, nextMonth);
            }
         }
      });
      workflowStore.lastWorkflowRunDate.subscribe((runDate) => {
         this._lastWorkflowRunTaskDate.next(DateTime.fromISO(runDate));
         this.getLastWorkflowRunTasks();
      });
   }

   public setLastWorkflowRunDate(value: DateTime) {
      //Don't update the date if it's the same
      if (value) {
         if (
            !this._lastWorkflowRunTaskDate.getValue() ||
            !value.equals(this._lastWorkflowRunTaskDate.getValue())
         ) {
            this._lastWorkflowRunTaskDate.next(value);
            this.getLastWorkflowRunTasks();
         }
      }
   }

   public setMyTasksDate(value: Date) {
      //Don't update the date if it's the same
      console.log(
         `Attemping to update my Task Date to ${value} current value ${this.currentTaskDate}`
      );
      if (value && !isNaN(value.getTime())) {
         if (
            !this.currentTaskDate ||
            !DateTime.fromJSDate(value).equals(
               DateTime.fromJSDate(this.currentTaskDate)
            )
         ) {
            this.currentTaskDate = value;
            if (value) {
               localStorage.setItem(
                  this.MyTaskSelectedDateStorageKey,
                  value.toISOString()
               );
               this._myTasksDate.next(value);
            }
         }
      }
   }

   getTaskData(): void {
      if (this.currentPropertyId && this.currentTaskDate) {
         const currentDate = DateTime.fromJSDate(this.currentTaskDate);
         const nextDay = currentDate.plus({ days: 1 });

         this.updateAssignedTasks(currentDate, nextDay);
         this.updateAllPropertyTasks(currentDate, nextDay);

         const currentMonth = currentDate.startOf('month');
         const nextMonth = currentDate.plus({ months: 1 });
         this.updateMyTasksMonthly(currentMonth, nextMonth);
         this.updateAllTaskMonthly(currentMonth, nextMonth);
      }
   }

   updateAllTaskMonthly(currentMonth: DateTime, nextMonth: DateTime) {
      if (this.currentPropertyId && this.currentTaskDate) {
         const loadingState = new LoadingState();
         loadingState.AllTasks = true;
         this.loadingOverlayStore.setLoadingState(loadingState);
         this.taskService
            .getAllTasks(currentMonth, nextMonth, this.currentPropertyId)
            .subscribe((data) => {
               this._allDailyTasksBySelectedDate.next(data);
               loadingState.AllTasks = false;
               this.loadingOverlayStore.setLoadingState(loadingState);
            });
      }
   }

   updateMyTasksMonthly(currentMonth: DateTime, nextMonth: DateTime) {
      if (this.currentPropertyId && this.currentTaskDate) {
         const loadingState = new LoadingState();
         loadingState.MyTasks = true;
         this.loadingOverlayStore.setLoadingState(loadingState);
         this.taskService
            .getMyTasks(currentMonth, nextMonth, this.currentPropertyId)
            .subscribe((data) => {
               this._myDailyTasksBySelectedDate.next(data);
               loadingState.MyTasks = false;
               this.loadingOverlayStore.setLoadingState(loadingState);
            });
      }
   }

   updateAllPropertyTasks(currentDate: DateTime, nexDate: DateTime) {
      if (this.currentPropertyId && this.currentTaskDate) {
         const loadingState = new LoadingState();
         loadingState.AllPropertyTasks = true;
         this.loadingOverlayStore.setLoadingState(loadingState);
         this.taskService
            .getAllPropertyTasks(currentDate, nexDate, this.currentPropertyId, false)
            .subscribe((tasks) => {
               this._allTasksBySelectedDate.next(tasks);
               loadingState.AllPropertyTasks = false;
               this.loadingOverlayStore.setLoadingState(loadingState);
            });
      }
   }

   updateAssignedTasks(currentDate: DateTime, nexDate: DateTime) {
      if (this.currentPropertyId && this.currentTaskDate) {
         const loadingState = new LoadingState();
         loadingState.MyAssigned = true;
         this.loadingOverlayStore.setLoadingState(loadingState);
         this.taskService
            .getAssignedTasks(currentDate, nexDate, this.currentPropertyId, false)
            .subscribe((tasks) => {
               this._myTasksBySelectedDate.next(tasks);
               loadingState.MyAssigned = false;
               this.loadingOverlayStore.setLoadingState(loadingState);
            });
      }
   }

   updateClaimedTasks() {
      if (this.currentPropertyId != undefined) {
         const loadingState = new LoadingState();
         loadingState.AllAssigned = true;
         this.taskService
            .getAllAssignedTasks(this.currentPropertyId)
            .subscribe((tasks) => {
               this._claimedTasks.next(tasks.filter((task) => !task.isComplete));
               loadingState.AllAssigned = false;
               this.loadingOverlayStore.setLoadingState(loadingState);
            });
      }
   }

   updateUnclaimedTasks() {
      if (this.currentPropertyId != undefined) {
         const loadingState = new LoadingState();
         loadingState.UnclaimedTasks = true;
         this.loadingOverlayStore.setLoadingState(loadingState);
         this.taskService
            .getAllUnclaimedByProperty(this.currentPropertyId)
            .subscribe((tasks) => {
               this._unclaimedTasks.next(tasks);
               loadingState.UnclaimedTasks = false;
               this.loadingOverlayStore.setLoadingState(loadingState);
            });
      }
   }

   sendCreateTask(task: CreateTaskRequest): Observable<PhobosTaskTemplate> {
      const loadingState = new LoadingState();
      loadingState.PhobosTask = true;
      this.loadingOverlayStore.setLoadingState(loadingState);
      return this.taskService.createTask(task);
   }

   sendUpdateTask(task: UpdateTaskRequest): Observable<PhobosTaskTemplate> {
      return this.taskService.editTask(task);
   }

   getData(): void {
      this.updateUnclaimedTasks();
      this.updateClaimedTasks();
   }

   refreshStore(): void {
      if (this.currentPropertyId != undefined) {
         console.log(`Refreshing mentions for ${this.currentPropertyId}`);
         this.getData();
         this.getTaskData();
      }
   }

   private getLastWorkflowRunTasks() {
      if (this.currentPropertyId && this.lastWorkflowRunTaskDate) {
         const loadingState = new LoadingState();
         loadingState.LastWorkflowRunTasks = true;
         this.loadingOverlayStore.setLoadingState(loadingState);
         this.taskService
            .getAllPropertyTasks(
               this._lastWorkflowRunTaskDate.getValue(),
               this._lastWorkflowRunTaskDate.getValue().plus({ days: 1 }),
               this.currentPropertyId,
               false
            )
            .subscribe((tasks) => {
               const result = this.filterTasks(tasks);
               this._lastWorkflowRunTasks.next(result);
               loadingState.LastWorkflowRunTasks = false;
               this.loadingOverlayStore.setLoadingState(loadingState);
            });
      }
   }

   private compareProperties(newProp: Property): boolean {
      console.log(
         `Comparing current Property ${this.currentPropertyId} to new ${newProp.id}`
      );
      return !this.currentPropertyId || this.currentPropertyId !== newProp.id;
   }

   private filterTasks(tasks: Task[]): Task[] {
      if (!this.userPermissions.forms.read) {
         return tasks.filter((f) => {
            return f.type != WorkflowType.Form;
         });
      }
      return tasks;
   }
}
