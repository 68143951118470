import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RowButton } from 'src/app/panel-components/models/row-button';
import { LoadingOverlayStoreService } from '../../../services/stores/loading-overlay-store.service';
import { PropertyStoreService } from '../../../services/stores/property-store.service';
import { GroupStoreService } from '../../../services/stores/group-store.service';
import { TaskService } from '../../../services/task.service';
import { PhobosTaskTemplate } from '../../../domain/phobos-task-template';
import { Group } from '../../../domain/group';
import { AutoRunStoreService } from '../../../services/stores/auto-run-store.service';
import { AutoRunSchedule } from '../../../domain/auto-run-schedule';
import { TaskStage } from '../../../domain/task-stage';
import { RefreshDataState } from '../../../domain/refresh-data-state';
import { DropDownStoreService } from '../../../services/stores/drop-down-store.service';
import { DataStoreService } from '../../../services/data-store.service';
import { TaskTemplateService } from '../../../services/stores/task-template.service';
import { Table } from 'primeng/table';

@Component({
   selector: 'app-task-management',
   templateUrl: './task-management.component.html',
   styleUrls: ['./task-management.component.scss']
})
export class TaskManagementComponent implements OnInit, OnDestroy {
   isDialogVisible = false;
   tasks: PhobosTaskTemplate[] = [];
   isLoading: boolean = false;
   headingButtons: RowButton[];
   action: boolean = false;
   currentProp: number | undefined;
   currentProperty: number | undefined;
   groups: Group[] = [];
   autoRunSchedules: AutoRunSchedule[] = [];
   currentTaskTemplate?: PhobosTaskTemplate;
   searchValue: string | undefined;

   private subscriptions: Subscription = new Subscription();

   constructor(
      private loadingOverlayStore: LoadingOverlayStoreService,
      private taskService: TaskService,
      private propStore: PropertyStoreService,
      private groupStore: GroupStoreService,
      private autoRunStore: AutoRunStoreService,
      private taskTemplateStore: TaskTemplateService,
      private dataStoreService: DataStoreService,
      private dropDownStore: DropDownStoreService
   ) {
      this.currentProp = 0;
      this.headingButtons = [
         new RowButton(
            'add_group',
            'Create Task',
            'fal fa-plus',
            [
               'p-button-rounded',
               'p-button-success',
               'p-button-outlined',
               'p-mr-2',
               'p-mb-2'
            ],
            ['p-col-12', 'p-text-right'],
            'right'
         )
      ];
   }

   ngOnInit(): void {
      this.subscriptions.add(
         this.loadingOverlayStore.LoadingState.subscribe((loadingState) => {
            this.isLoading = !(
               !loadingState.AutoRun &&
               !loadingState.PhobosTask &&
               !loadingState.PhobosTaskTemplates &&
               !loadingState.Groups
            );
         })
      );
      this.updateDropDownState(false);
      this.subscriptions.add(
         this.propStore.currentProperty.subscribe((currentProp) => {
            this.currentProp = currentProp?.id;
            this.currentProperty = currentProp?.id;
         })
      );
      this.subscriptions.add(
         this.taskTemplateStore.taskTemplates.subscribe((templates) => {
            this.tasks = templates;
         })
      );
      this.subscriptions.add(
         this.autoRunStore.autoRunSchedules.subscribe((schedules) => {
            this.autoRunSchedules = schedules;
         })
      );
      this.subscriptions.add(
         this.groupStore.groups.subscribe((groups) => {
            this.groups = groups;
         })
      );
   }

   ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
   }

   clear(table: Table) {
      table.clear();
      this.searchValue = '';
   }

   playPauseAutoRun(task: PhobosTaskTemplate) {
      if (this.currentProp && !this.isDialogVisible) {
         this.autoRunStore
            .updateAutorunStatus(!task.isAutoRunEnabled, this.currentProp, task.id)
            .subscribe((t) => {
               task.isAutoRunEnabled = t.isActive;
            });
      }
   }
   handleTaskType(taskType: number): string {
      switch (taskType) {
         case 0:
            return 'Daily';
         case 1:
            return 'Non-daily';
         case 2:
            return 'Private';
         case 3:
            return 'Contracts';
         case 4:
            return 'Form';
         default:
            return 'Daily';
      }
   }

   getTaskClass(taskType: number): string {
      switch (taskType) {
         case 0:
            return 'schedule-tag-daily';
         case 1:
            return 'schedule-tag-non-daily';
         case 2:
            return 'schedule-tag-private';
         case 3:
            return 'schedule-tag-contracts';
         case 4:
            return 'schedule-tag-form';
         default:
            return 'schedule-tag-daily';
      }
   }

   handleDisableEditButton(taskType: number): boolean {
      //If the edit dialog is open,disable edit button
      if (this.isDialogVisible) {
         return true;
      }
      switch (taskType) {
         case 0:
         case 1:
         case 2:
         case 3:
            return false;
         case 4:
            return true;
         default:
            return false;
      }
   }
   // Method to update boolean inside DropDownStoreService
   updateDropDownState(value: boolean): void {
      this.dropDownStore.setDropDownState(value);
   }

   formatDate(dateString: string): string {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
         return 'Invalid Date';
      }
      const options: Intl.DateTimeFormatOptions = {
         year: 'numeric',
         month: 'long',
         day: 'numeric'
      };
      return date.toLocaleDateString(undefined, options);
   }

   onDialogClose(): void {
      this.isDialogVisible = false;
      const refreshDate = new RefreshDataState();
      refreshDate.PhobosTaskTemplates = true;
      this.dataStoreService.refreshTaskData(refreshDate);
   }

   onDialogOpen(action: boolean, task?: PhobosTaskTemplate): void {
      this.action = action;
      this.currentTaskTemplate = task;
      this.isDialogVisible = true;
   }

   getGroupNameForStage(stage: TaskStage) {
      const group = this.groups.find((g) => g.id == stage.groupId);
      if (group) {
         return group.displayName;
      }
      return '';
   }

   protected readonly Array = Array;

   filterTable(input: Event | null, table: Table) {
      if (input) {
         const filterValue = (input.target as HTMLInputElement).value;
         table.filterGlobal(filterValue, 'contains');
      }
   }
}
