<div *ngIf="this.isLoading" class="progress-spinner">
	<p-progressSpinner></p-progressSpinner>
</div>

<div class="card">
	<div class="p-grid">
		<div class="col-3">
			<p-inputGroup>
					<input type="text" pInputText placeholder="Task Search"
								 [(ngModel)]="searchTerm"/>
				<button type="button" pButton label="Search" icon="pi pi-search" iconPos="right" (click)="search()"></button>
			</p-inputGroup>
			<p class="pt-2 search-text">Enter a Form field input, such as "Concierge", or an employee's Last Name, to search your property's Forms archives</p>
		</div>
		<div class="col-12">
			<app-day-task-table
				[taskList]="tasks"
			></app-day-task-table>
		</div>
	</div>
</div>
