<div class="p-grid">
	<div class="p-sm-12 p-md-12 p-lg-12">
		<div class="relative card container">
			<div class="absolute tag-positioning">
				<div
					*ngIf="reportData.length > 0 && this.lastRequest"
					class="heading-canvas mx-auto"
				>
					<div class="flex tag-padding">
						<h6 class="dark-purple-text font-bold mb-0">
                     <span class="mr-1 font-bold tag-font-size">
                        {{ this.reportData.length }} records:
                     </span>
							<span class="text-white font-semibold tag-font-size"
							>{{ this.lastRequest.start }}
								<span class="dark-purple-text font-bold tag-font-size"
								>to</span
								><span></span
								></span>
							<span class="text-white font-semibold tag-font-size">
                        {{ this.lastRequest.finish }}
                     </span>
						</h6>
					</div>
				</div>
			</div>

			<div id="mention_table">
				<div class="tyalls-table-name">
					<h6 class="tyalls-table-heading">Portfolio Snapshot</h6>
				</div>
			</div>

			<div class="px-1">
				<form #formDir="ngForm" [formGroup]="portfolioReportForm">
					<div class="p-field flex w-full justify-content-between mb-5 mt-4">
						<div class="flex justify-content-between gap-2">
							<div class="p-field-radiobutton task-canvas flex">
								<p-radioButton
									formControlName="dateRangeOption"
									inputId="current_month"
									name="dateRangeOption"
									value="current_month"
									class="mr-2 cursor-pointer"
								></p-radioButton>
								<div class="mr-1 content-center">
									<label class="cursor-pointer text-sm"
									>Current Month</label
									>
								</div>
							</div>
							<div class="p-field-radiobutton task-canvas flex">
								<p-radioButton
									formControlName="dateRangeOption"
									inputId="last_month"
									name="dateRangeOption"
									value="last_month"
									class="mr-2 cursor-pointer"
								></p-radioButton>
								<div class="mr-1 content-center">
									<label class="cursor-pointer text-sm">Last Month</label>
								</div>
							</div>
							<div class="p-field-radiobutton task-canvas flex">
								<p-radioButton
									formControlName="dateRangeOption"
									inputId="last_week"
									name="dateRangeOption"
									value="last_week"
									class="mr-2 cursor-pointer"
								></p-radioButton>
								<div class="mr-1 content-center">
									<label class="cursor-pointer text-sm">Last Week</label>
								</div>
							</div>
							<div class="p-field-radiobutton task-canvas flex">
								<p-radioButton
									formControlName="dateRangeOption"
									inputId="today"
									name="dateRangeOption"
									value="today"
									class="mr-2 cursor-pointer"
								></p-radioButton>
								<div class="mr-1 content-center">
									<label class="cursor-pointer text-sm">Today</label>
								</div>
							</div>
							<div class="p-field-radiobutton task-canvas flex">
								<p-radioButton
									formControlName="dateRangeOption"
									inputId="custom"
									name="dateRangeOption"
									value="custom"
									class="mr-2 cursor-pointer"
								></p-radioButton>
								<div class="mr-1 content-center">
									<label class="cursor-pointer text-sm">Custom</label>
								</div>
							</div>
						</div>

						<div class="flex justify-content-end gap-2 text-sm">
							<p-button
								(onClick)="this.saveReportToFile($event)"
								[disabled]="this.reportData.length === 0"
								[loading]="this.isSaving"
								icon="fal fa-save"
								label="Save Report"
							></p-button>

							<p-button
								(onClick)="this.runReport($event)"
								[disabled]="this.portfolioReportForm.invalid"
								[loading]="this.isRunning"
								icon="fal fa-play"
								label="Run"
								class="mr-2"
							></p-button>
						</div>

					</div>

					<div
					*ngIf="dateRangeOption.value === 'custom'"
					class="grid gap-6 mt-2 task-canvas pb-3 pl-3 pr-3 pt-4 mx-auto"
				>
					<div class="p-field">
                     <span class="p-float-label">
                        <p-calendar
													[maxDate]="this.maxFilterDate"
													[minDate]="this.minFilterDate"
													[required]="true"
													[showIcon]="true"
													dateFormat="yy-mm-dd"
													formControlName="startDate"
													inputId="startDate"
												></p-calendar>
                        <label>Start Date</label>
                     </span>
					</div>

					<!-- Finish -->
					<div class="p-field">
                     <span class="p-float-label">
                        <p-calendar
													[maxDate]="this.maxFilterDate"
													[minDate]="this.minFilterDate"
													[required]="true"
													[showIcon]="true"
													dateFormat="yy-mm-dd"
													formControlName="finishDate"
													inputId="finishedDate"
												></p-calendar>
                        <label>Finished Date</label>
                     </span>
					</div>
				</div>
				</form>
			</div>

			<div *ngIf="reportData.length > 0" class="mx-auto chart-width pt-5 pb-6">
				<p-chart type="doughnut" [data]="data" [options]="options" />
			</div>


			<div *ngIf="reportData.length > 0" class="p-5 pt-0">
				<p-table
					[value]="this.reportData"
					dataKey="processId"
					responsiveLayout="scroll"
					[resizableColumns]="true"
					styleClass="p-datatable-gridlines"
				>
					<ng-template pTemplate="header">
						<tr>
							<th class="text-sm">Property</th>
							<th class="text-center text-sm">Completed</th>
							<th class="text-center text-sm">Not Completed</th>
							<th class="text-center text-sm">Overdue</th>
							<th class="text-center text-sm">Total</th>
						</tr>
					</ng-template>
					<ng-template let-expanded="expanded" let-reportData pTemplate="body">
						<tr>
							<td class="text-sm">{{ reportData.propName }}({{ reportData.propCode }})</td>
							<td class="text-center text-sm">{{ reportData.completed }}</td>
							<td class="text-center text-sm">{{ reportData.notCompleted }}</td>
							<td class="text-center text-sm">{{ reportData.overdue }}</td>
							<td class="text-center text-sm">{{ reportData.total }}</td>
						</tr>
					</ng-template>
				</p-table>
			</div>

			<div *ngIf="reportData.length === 0 && this.hasRun === true">
				<p class="text-center font-semibold mt-6 mb-4">No report returned</p>
			</div>
		</div>
	</div>
</div>
